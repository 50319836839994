import React, { useState } from 'react'
import { withStyles } from "@material-ui/core/styles"

import Loader from "../Loader/Loader";
import CopyButton from '../Buttons/CopyButton';

import {
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Box,
  Divider,
  Tabs,
  Tab,
  Grid,
} from '@material-ui/core'

import {
  ExpandMore,
  ExpandLess,
  FileCopyOutlined as FileCopy,
} from '@material-ui/icons'

const styles = theme => ({
  root: {
    width: '100%',
    height: '100%',
  },
  curlDisplayRoot: {
    textAlign: 'left'
  },
  fullHeight: {
    height: '100%'
  },
  card: {
    width: '100%',
    height: '100%',
    boxShadow: theme.shadows[2],
  },
  cardContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    marginBottom: '20px',
    marginTop: '20px',
  },
  responseHeader: {
    display: 'flex',
    justifyContent: 'space-between', // Align header items horizontally
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  responseTypography: {
    textAlign: 'left',
    paddingTop: '6px'
  },
  responseContainer: {
    position: 'relative'
  },
  loader: {
    paddingRight: '20px'
  },
  expandButton: {
    marginBottom: '5px',
    padding: '11px 0px 8px',
  },
})

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box position="relative">
          {children}
        </Box>
      )}
    </Box>
  );
}

const APIResponseDisplay = ({ classes, response }) => {
  const [showResponse, setShowResponse] = useState(true)
  const [currentTab, setCurrentTab] = useState(0)


  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const { external_api, ...internalResponse } = response

  return (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Box className={classes.responseHeader}>
                <Typography variant="body2" className={classes.responseTypography}>28East API Response</Typography>
              </Box>
              <Box className={classes.responseContainer} mt={2}>
                <TextField
                  fullWidth
                  fullHeight
                  multiline
                  rows={19}
                  variant="outlined"
                  value={JSON.stringify(internalResponse || undefined, null, 4)}
                  disabled={true}
                  id='responseDisplay'
                />
                {internalResponse&&
                  <CopyButton text={JSON.stringify(internalResponse || undefined, null, 4)} />
                }
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Box className={classes.responseHeader}>
                <Typography variant="body2" className={classes.responseTypography}>External API Responses</Typography>
              </Box>
              <Box>
                {!external_api &&
                  <CurlRequestDisplay classes={classes}/>
                }
                {external_api?.length > 1 &&
                  (<>
                    <Tabs
                      indicatorColor="primary"
                      textColor="primary"
                      value={currentTab}
                      onChange={handleTabChange}
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      {
                        external_api.map((externalData, index) => {
                          return (<Tab label={<Typography variant="caption" className={classes.responseTypography}>{`Request: ${index + 1}`}</Typography>} />)
                        })
                      }
                    </Tabs>
                    <>
                      {external_api.map((externalData, index) => (
                        <TabPanel value={currentTab} index={index} mt={2}>
                          <CurlRequestDisplay classes={classes} data={externalData} />
                        </TabPanel>
                      ))}
                    </>
                  </>)
                }
                {(external_api?.length === 1) &&
                  <CurlRequestDisplay classes={classes} data={external_api[0]} />
                }
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  )
}

const CurlRequestDisplay = ({ classes, data }) => {
	return <Box className={classes.curlDisplayRoot}>
    <Box className={classes.responseContainer} mb={1}>
      <Typography variant='caption' color='disabled'>
        Curl Request
      </Typography>
      <TextField
        placeholder='Curl Request'
        fullWidth
        multiline
        rows={5}
        variant="outlined"
        value={data?.curl_request}
      disabled={true}
      id='responseDisplay'
      />
      {data?.curl_request &&
        <CopyButton text={data?.curl_request} disabled={!data || !data?.curl_request} />
      }
    </Box>
    <Box className={classes.responseContainer}>
      <Typography variant='caption' color='text.secondary'>
        Response
      </Typography>
      <TextField
        placeholder='Response'
        fullWidth
        multiline
        rows={10}
        variant="outlined"
        value={!data ? '' : JSON.stringify(data?.response || { code: data?.code, message: data?.message }, null, 4)}
        disabled={true}
        id='responseDisplay'
      />
      {data&&
        <CopyButton text={JSON.stringify(data?.response || { code: data?.code, message: data?.message }, null, 4)} />
      }
    </Box>
  </Box>
}

export default withStyles(styles)(APIResponseDisplay)