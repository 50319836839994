// @material-ui/icons
import {
    Dashboard,
    People,
    Lock,
    Queue,
    AttachMoney,
    Layers,
    Category,
    StarHalf,
    Beenhere,
    Link,
    Dns,
    Http,
    Apps as AppsIcon,
    SettingsEthernet,
    PinDrop,
    DoneAll,
    InsertLink,
    Pageview,
    LibraryBooks,
    BusinessCenter as Toolkit,
    AccountBalanceWallet
} from "@material-ui/icons";


// core components/views for Admin layout
import DashboardPage from "../views/Dashboard";
import Clients from "../views/Clients";
import APIManagement from "../views/APIManagement";
import Subscriptions from "../views/Subscriptions";
import ClientDomains from "../views/ClientDomains";
import GMPUsageBillingReports from "../views/GMPUsageBillingReports";
import Providers from "../views/Providers";
import Services from "../views/Services";
import Apps from "../views/Apps";
import Statuses from "../views/Statuses";
import ServiceCoverage from "../views/ServiceCoverage";
import DataSources from "../views/DataSources";
import Users from "../views/Users";
import ExternalAPIs from "../views/ExternalAPIs";
import PostMessageTest from "../views/PostMessageTest";
import Bulk from "../views/Bulk";
import URLParser from "../views/URLParser";
import FindMyCoverageResults from "../views/FindMyCoverageResults";
import CoverageAppDocs from "../views/CoverageAppDocs"
import ClientBusinessRules from "../views/ClientBusinessRules"
import ExternalAPIToolkit from "../views/ExternalAPIToolkit"

const AdminConsoleRoutes = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: Dashboard,
        component: DashboardPage,
        layout: "/admin"
    }, {
        path: "/clients",
        name: "Clients",
        icon: People,
        component: Clients,
        layout: "/admin",
        env: ['prod', 'staging', 'dev', 'stagingsim'],
    }, {
        path: "/apimanagement",
        name: "API Management",
        icon: Lock,
        component: APIManagement,
        layout: "/admin",
        env: ['prod', 'staging', 'dev', 'stagingsim'],
    }, {
        path: "/subscriptions",
        name: "Client Subscriptions",
        icon: Queue,
        component: Subscriptions,
        layout: "/admin",
        env: ['prod', 'staging', 'dev', 'stagingsim'],
    }, {
        path: "/domains",
        name: "Client Domains",
        icon: Dns,
        component: ClientDomains,
        layout: "/admin",
        env: ['prod', 'staging', 'dev', 'stagingsim'],
    }, {
        path: "/apps",
        name: "Apps",
        icon: AppsIcon,
        component: Apps,
        layout: "/admin",
        env: ['prod', 'staging', 'dev', 'stagingsim'],
    }, {
        path: "/providers",
        name: "Providers",
        icon: Layers,
        component: Providers,
        layout: "/admin",
    }, {
        path: "/services",
        name: "Services",
        icon: Category,
        component: Services,
        layout: "/admin",
    }, {
        path: "/statuses",
        name: "Statuses",
        icon: StarHalf,
        component: Statuses,
        layout: "/admin",
    }, {
        path: "/servicecoverage",
        name: "Service Coverage",
        icon: Beenhere,
        component: ServiceCoverage,
        layout: "/admin",
    }, {
        path: "/sources",
        name: "Data Sources",
        icon: Link,
        component: DataSources,
        layout: "/admin",
    }, {
        path: "/externalapis",
        name: "External APIs",
        icon: Http,
        component: ExternalAPIs,
        layout: "/admin",
        env: ['prod', 'staging', 'dev', 'stagingsim'],
    }, {
        path: "/users",
        name: "Users",
        icon: People,
        component: Users,
        layout: "/admin"
    }, {
        path: "/clientrules",
        name: "Client Business Rules",
        icon: AccountBalanceWallet,
        component: ClientBusinessRules,
        layout: "/admin"
    }, {
        path: "/coveragedocs",
        name: "Coverage App Docs",
        icon: LibraryBooks,
        component: CoverageAppDocs,
        layout: "/admin"
    }, {
        path: "/tests/postmessage",
        name: "PostMessage Test",
        icon: PinDrop,
        component: PostMessageTest,
        layout: "/admin",
        env: ['prod', 'staging', 'dev', 'stagingsim'],
    }, {
        path: "/tests/externalapitoolkit",
        name: "External API Toolkit",
        icon: Toolkit,
        component: ExternalAPIToolkit,
        layout: "/admin",
        env: ['prod', 'staging', 'dev', 'stagingsim'],
    }, {
        path: "/tests/params",
        name: "URL Params Decode",
        icon: SettingsEthernet,
        component: URLParser,
        layout: "/admin",
        env: ['prod', 'staging', 'dev', 'stagingsim'],
    }, {
        path: "/findMyCoverageResults",
        name: "Find My Coverage",
        icon: Pageview,
        component: FindMyCoverageResults,
        layout: "/admin",
        env: ['prod', 'staging', 'dev', 'stagingsim'],
    }, {
        path: "/feasibility/bulk",
        name: "Bulk Feasibility",
        icon: DoneAll,
        component: Bulk,
        layout: "/admin",
        env: ['prod', 'staging', 'dev', 'stagingsim'],
    },
    // ,{
    //     path: "/gmp/usage/billing",
    //     name: "GMP Billing",
    //     icon: AttachMoney,
    //     component: GMPUsageBillingReports,
    //     layout: "/admin"
    // }
]

const Routes = AdminConsoleRoutes.filter(route => (!route.env || route.env.some(env => env === process.env.REACT_APP_CONSOLE_ENV)))


export default Routes;
