import React, { Component } from "react"
import {
  Box,
  Container,
  Paper,
  Typography,
  makeStyles,
  useTheme,
  IconButton
} from "@material-ui/core"
import {
  Refresh
} from '@material-ui/icons'
import withStyles from "@material-ui/core/styles/withStyles"

const appStyle = (theme) => ({
  root: {
    display: "flex",
    width: "100%",
    height: `calc(100vh - 130px)`,
    'flex-direction': 'column'
  },
  iframe: {
    'border-style': 'none'
  }
})

class CoverageAppDocs extends Component {

  handleRefresh = () => {
    const iframe = document.getElementById("coverage-docs-iframe")

    iframe.contentWindow.postMessage("refresh", `${process.env.REACT_APP_COVERAGE_DOCS_URL}`)
  }

  render() {
    const { classes } = this.props
    return (
      <Paper className={classes.root}>
        <iframe
          id='coverage-docs-iframe'
          className={classes.iframe}
          width={"100%"}
          height={"100%"}
          src={`${process.env.REACT_APP_COVERAGE_DOCS_URL}`}
        ></iframe>
      </Paper>
    )
  }
}

export default withStyles(appStyle)(CoverageAppDocs)
