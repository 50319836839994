import React from 'react';
import clsx from 'clsx';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import classNames from "classnames";
import Icon from "@material-ui/core/Icon";
import {NavLink} from "react-router-dom";

const drawerWidth = 250;
const whiteColor = "#2b2b2b";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7) + 1,
        },
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    whiteFont: {
        color: whiteColor
    },
    logo: {
        position: "relative",
        padding: "10px 10px",
        zIndex: "4",
        "&:after": {
            content: '""',
            position: "absolute",
            bottom: "0",
            height: "1px",
            right: "15px",
            width: "calc(100% - 30px)",
        }
    },
    logoLink: {
        textTransform: "uppercase",
        padding: "5px 0",
        display: "block",
        fontSize: "16px",
        textAlign: "left",
        fontWeight: "400",
        lineHeight: "30px",
        textDecoration: "none",
        backgroundColor: "transparent",
        "&,&:hover": {
            color: whiteColor
        }
    },
    logoImageContainer: {
        width: "30px",
        display: "inline-block",
        maxHeight: "30px",
        marginLeft: "5px",
        marginRight: "5px"
    },
    logoImage: {
        width: "30px"
    },
    itemContent: {
        color:whiteColor,
        "text-decoration": "none",
        "&,&:hover": {
            color: whiteColor
        }
    },
    active:{
        color: '#fff',
        backgroundColor: '#2196f3',
        "&,&:hover": {
            color: '#fff',
            backgroundColor: '#2196f3',
        }
    }
}));

const Sidebar = ({...props}) => {
    const classes = useStyles();
    const theme = useTheme();

    // verifies if routeName is the one active (in browser input)
    function activeRoute(routeName) {
        return props.location.pathname.indexOf(routeName) > -1 ? true : false;
    }

    const {color, logo, image, logoText, routes} = props;

    let links = (
        <List className={classes.list}>
            {routes.map((prop, key) => {
                let listItemClasses;
                listItemClasses = classNames({
                    [" " + classes[color]]: activeRoute(prop.layout + prop.path)
                });
                const whiteFontClasses = classNames({
                    [" " + classes.active]: activeRoute(prop.layout + prop.path)
                });
                return (
                    <NavLink
                        // to={!activeRoute(prop.layout + prop.path) ? prop.layout + prop.path : '#'}
                        to={prop.layout + prop.path}
                        className={classes.itemContent}
                        activeClassName="active"
                        key={key}
                    >
                        <ListItem button key={key} className={listItemClasses} >
                            <ListItemIcon>{
                                typeof prop.icon === "string" ? (
                                    <Icon className={classNames(whiteFontClasses)}>
                                        {prop.icon}
                                    </Icon>
                                ) : (
                                    <prop.icon
                                        className={classNames(whiteFontClasses)}
                                    />
                                )
                            }</ListItemIcon>
                            <ListItemText primary={prop.name}/>
                        </ListItem>
                    </NavLink>
                );
            })}
        </List>
    );
    let brand = (
        <div className={classes.logo}>
            <a
                href="/"
                className={classNames(classes.logoLink)}
            >
                <div className={classes.logoImageContainer}>
                    <img src={logo} alt="28East (Pty) Ltd." className={classes.logoImage}/>
                </div>
                {logoText}
            </a>
        </div>
    );
    return (
        <div className={classes.root}>
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: props.open,
                    [classes.drawerClose]: !props.open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: props.open,
                        [classes.drawerClose]: !props.open,
                    }),
                }}
                open={props.open}
            >
                <div className={classes.toolbar}>
                    {brand}
                    <IconButton onClick={props.handleDrawerToggle}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon/> : <ChevronLeftIcon/>}
                    </IconButton>
                </div>
                <Divider/>
                {links}
            </Drawer>
        </div>
    );
};
export default Sidebar;
