import React, { useState } from 'react'

import Loader from "../components/Loader/Loader";
import Alert from "../components/Alert/Alert";
import APIConfigContainer from "../components/APIComponents/APIConfigContainer";
import APIRequestContainer from '../components/APIComponents/APIRequestContainer'
import APIResponseDisplay from "../components/APIComponents/APIResponseDisplay";
import ExternalAPIRequest from "../services/ExternalAPIservice"

import { withStyles } from "@material-ui/core/styles"

import {
  Container,
  Grid,
} from '@material-ui/core'

const styles = theme => ({
  container: {
    padding: '0px'
  },
  subContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
})

const ApiToolKit = ({classes}) => {
  const [loading, setLoading] = useState(true)
  const [errorType, setErrorType] = useState({
    requestError: false,
    missingInputError: false,
    requestBodyError: false
  })
  const [response, setResponse] = useState('')
  const [requestDetails, setRequestDetails] = useState({
    baseUrl: '',
    data: {},
    apiConfig: '',
    externalApi: '',
    requiredParams: {},
  })
  const [alertState, setAlertState] = useState({
    message: '',
    state: '',
    open: false,
  })

  const handleRequestDetails = (value) => {
    setRequestDetails(value)
  }

  const handleErrorType = (value) => {
    setErrorType(value)
  }

  const handleLoading = (value) => {
    setLoading(value)
  }

  const handleRequest = async () => {

    const scrollToResponse = () => {
      const target = document.getElementById('responseDisplay');

      if (target) {
        target.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    }

    try {
      if (!requestDetails || !requestDetails.data || !requestDetails.baseUrl) {
        throw { message: 'Invalid request' }
      }

      if (errorType.requestBodyError && typeof requestDetails.data !== 'object') {
        throw { message: 'Invalid JSON' }
      }

      const paramKey = Object.keys(requestDetails.data)

      const missingParams = paramKey.some(p => (requestDetails.requiredParams[p] && !requestDetails.data[p]) || requestDetails.data[p] === 'Parameter Required')

      if (missingParams) {
        setErrorType((prev) => {return {...prev, requestBodyError: true, missingInputError: true}})
        throw { message: 'Invalid request, required Parameter(s) missing' }
      }

      setErrorType((prev) => {return {...prev, requestError: false, requestBodyError: false, missingInputError: false}})
      setLoading(true)
      const newResponse = await ExternalAPIRequest(requestDetails)
      setLoading(false)

      if (newResponse.success) {
        addAlert({
          message: `Request Successful`,
          state: 'success',
          open: true,
        })
      } else {
        addAlert({
          message: `Request Failed: ${newResponse.code}`,
          state: 'error',
          open: true,
        })
      }

      setResponse(newResponse)
      scrollToResponse()
    } catch (error) {
      setLoading(false)
      setResponse('')
      setErrorType((prev) => {return {...prev, requestError: true}})
      addAlert({
        message: error.message || 'Request Failed unexpectedly',
        state: 'error',
        open: true,
      })
    }
  }

  const handleAlertClose = (alertToClose) => {
    setAlertState({message: '', state: '', open: false})
  }

  const addAlert = (newAlert) => {
    if (newAlert.message !== alertState.message) setAlertState(newAlert);
  }

  return (
    <Container maxWidth="xl" className={classes.container}>
    <Alert {...alertState} handleClose={handleAlertClose}/>
    {loading && (
      <Loader />
    )}

      {/* Setup Section */}
      <Grid container spacing={2}>

        {/* Configuration Section */}
        <Grid item xs={12} sm={3} className={classes.subContainer}>
          <APIConfigContainer
            handleLoading={handleLoading}
            handleErrorType={handleErrorType}
            handleRequestDetails={handleRequestDetails}
            requestDetails={requestDetails}
            errorType={errorType}
            addAlert={addAlert}
          />
        </Grid>

        {/* Request Section */}
        <Grid item xs={12} sm={9} className={classes.subContainer}>
          <APIRequestContainer
            loading={loading}
            requestDetails={requestDetails}
            handleLoading={handleLoading}
            handleRequest={handleRequest}
            handleRequestDetails={handleRequestDetails}
            handleErrorType={handleErrorType}
            errorType={errorType}
            addAlert={addAlert}
          />
        </Grid>

        {/* Response Section */}
        <Grid item xs={12} sm={12} className={classes.subContainer}>
          <APIResponseDisplay
            response={response}
          />
        </Grid>
      </Grid>

    </Container>
  )
}

export default withStyles(styles)(ApiToolKit)