import React, { Component } from "react"
import clsx from 'clsx';
import {
  Box,
  Container,
  Paper,
  Typography,
  makeStyles,
  useTheme,
  IconButton,
  List,
  ListItem
} from "@material-ui/core"
import {
  ChevronLeft,
  ChevronRight,
  Menu
} from '@material-ui/icons'
import withStyles from "@material-ui/core/styles/withStyles"

const drawerWidth = 230;

const appStyle = (theme) => ({
  root: {
    display: "flex",
    width: "100%",
    height: `calc(100vh - 130px)`,
    flexDirection: 'row'
  },
  menu: {
    position: 'absolute',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#2196f3'
    },
    color: '#757575',
  },
  menuOpen: {
    top: '95px',
    left: '200px',
  },
  menuClosed: {
    top: '95px',
  },
  list: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    transition: 'width 0.5s ease-in-out',
    paddingTop: '30px'
  },
  listClosed: {
    width: 0,
    borderRight: '1px solid rgb(230, 230, 230)',
  },
  listOpen: {
    flexShrink: 0,
    width: drawerWidth,
    position: 'relative',
    borderRight: '1px solid rgb(230, 230, 230)',
  },
  listItem: {
    cursor: 'pointer',
    '&:hover': {
      color: '#2196f3',
      backgroundColor: '#ebebeb'
    },
    '&.Mui-selected': {
      color: '#2196f3',
      backgroundColor: '#fff'
    }
  },
  iframe: {
    'border-style': 'none'
  },
  iframeContainer: {
    width: '100%',
    display: 'flex',
  }
})

const open = true

const clientUrls = {
  skywire: {
    url: "https://docs.google.com/document/d/e/2PACX-1vRvvhBrrCJJFmVgBfLjxW3QTsQEkI8m3IfeQ1GCLSPqXWBAqsBRUP5IUOIAw9qyylV0fVbdjNT2Qexl/pub?embedded=true",
    fullName: 'SkyWire Business Rules',
    name: 'skywire'
  },
  wiocc: {
    url: "https://docs.google.com/document/d/e/2PACX-1vTspduaIBNq0ze2-PotwEV-xGsI9wYf2T1fUSASTp7ZUhVb0FozfjfuRnSBGreLowEsk0DE3kn9ED1t/pub?embedded=true",
    fullName: 'Wiocc Business Rules',
    name: 'wiocc'
  },
  linkafrica: {
    url: "https://docs.google.com/document/d/e/2PACX-1vTrsVWzzf7EcOQKBG2XEZ33vQeyB4dreZBOd8rTqj9yLQ52R6RJBGT1JgHyy0QEgOTnrNOE35Ywy92N/pub?embedded=true",
    fullName: 'Link Africa Business Rules',
    name: 'linkafrica'
  }
}

class CoverageAppDocs extends Component {

  constructor(props) {
    super(props);
    this.state = {
      listOpen: true,
      client: 'skywire'
    };
  }

  handleRefresh = () => {
    const iframe = document.getElementById("coverage-docs-iframe")

    iframe.contentWindow.postMessage("refresh", `${process.env.REACT_APP_COVERAGE_DOCS_URL}`)
  }

  onListItemClick = (client) => {
    this.setState({
      client
    })
  }

  toggleMenu = () => {
    this.setState({
      listOpen: !this.state.listOpen
    })
  }

  render() {
    const { classes } = this.props
    return (
      <Paper className={classes.root}>
        <List className={
          clsx(classes.list, {
            [classes.listOpen]: this.state.listOpen,
            [classes.listClosed]: !this.state.listOpen,
          })}>
          {Object.keys(clientUrls).map(client => (
            <ListItem selected={clientUrls[client].name === this.state.client} className={classes.listItem} key={clientUrls[client].name} onClick={() => this.onListItemClick(clientUrls[client].name)}>
              {clientUrls[client].fullName}
            </ListItem>
          ))}
        </List>
        <div className={classes.iframeContainer}>
          <iframe
            id='coverage-docs-iframe'
            className={classes.iframe}
            width={"100%"}
            height={"100%"}
            src={clientUrls[this.state.client].url}
          ></iframe>
        </div>
        <IconButton
          className={
            clsx(classes.menu, {
              [classes.menuOpen]: this.state.listOpen,
              [classes.menuClosed]: !this.state.listOpen,
            })}
          onClick={this.toggleMenu}
          disableRipple
          disableFocusRipple
        >
          {this.state.listOpen ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </Paper >
    )
  }
}

export default withStyles(appStyle)(CoverageAppDocs)
